<script lang="ts" setup>
const siteStore = useSiteStore();
const catalogStore = useCatalogStore();

import type { OfferMedium, RecommendationsResponse } from '~/types/Catalog';

const recommendationsBlock = ref<RecommendationsResponse>();
const recommendedOffers = ref<OfferMedium[]>();
const { query } = useRoute();

onMounted(async () => {
  if (import.meta.client && window.r46) {
    for (const block of siteStore.recommendationBlocks) {
      if (block.type === 'not_found' && query.from?.includes('catalog')) {
        window.r46('recommend', block.block_identifier, {}, async function (response: RecommendationsResponse) {
          if (!response.recommends.length) return;

          recommendationsBlock.value = { ...response, block_identifier: block.block_identifier };
          recommendedOffers.value = await catalogStore.getOffersList(response.recommends.join(','));
        });
      }
    }
  }
});
</script>

<template>
  <div class="flex flex-col items-center justify-center h-full">
    <div class="flex flex-col items-center py-20 px-4 text-center">
      <SvgoSkull404
        :font-controlled="false"
        class="w-32 text-hell-black-20"
        filled
      />

      <h1 class="text-2xl sm:text-[48px] mt-5 sm:mt-7">Страница не найдена</h1>

      <div class="flex gap-7 sm:gap-10 flex-wrap justify-center mt-10">
        <TheBtn
          color="outline"
          to="/"
        >
          На главную
        </TheBtn>

        <TheBtn to="/catalog">В каталог</TheBtn>
      </div>
    </div>

    <ProductCardsGallery
      v-if="recommendedOffers?.length"
      :offers="recommendedOffers"
      :recommendations-block-id="recommendationsBlock?.block_identifier"
      :title="`<h2>${recommendationsBlock?.title}</h2>`"
      class="mb-20"
    />
  </div>
</template>
